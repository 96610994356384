import {
  CameraEnhanceOutlined,
  Check,
  Close,
  DevicesOutlined,
  GroupsOutlined,
  InfoOutlined,
  InsertLink,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PhotoAlbumOutlined,
  PhotoSizeSelectLargeOutlined,
  ScreenShareOutlined,
  SlowMotionVideo,
  SortOutlined,
  SpaOutlined
} from '@mui/icons-material'
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import { isMobile } from 'mobile-device-detect'
import { useState } from 'react'

const CardStorage = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: 1,
  textTransform: 'none',
  [theme.breakpoints.up('sm')]: {
    padding: '0 10px',
    fontSize: '0.75rem',
    lineHeight: 1.25
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.5rem'
  }
}))

const TooltipSx: SxProps<Theme> = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'black',
    borderRadius: '1.5rem',
    fontSize: '.85rem',
    lineHeight: 1.8,
    maxWidth: '18rem',
    padding: '1rem'
  },
  '& .MuiTooltip-arrow': {
    color: 'black'
  }
}

export default function FeatureList({ plan }: Readonly<{ plan: 'storage' | 'sharing' | 'expert' }>) {
  const theme = useTheme()
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(false)
  const [openFeatureList, setOpenFeatureList] = useState(false)
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))

  // remove all these when ab test is completed
  const showNewFeatureListView = useFlag('showNewFeatureListView')

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setOpen(!open)
  }

  const handleToggle = () => {
    setOpenFeatureList(!openFeatureList)
  }

  const viewList = isMobile ? openFeatureList : true

  if (showNewFeatureListView) {
    return (
      <>
        {isMobile && (
          <IconButton
            disableRipple
            onClick={handleToggle}
            aria-label={!openFeatureList ? 'Expand' : 'Collapse'}
            sx={{
              color: 'primary.main',
              paddingTop: '30px',
              paddingBottom: '30px',
              height: '20px'
            }}
          >
            <CardStorage>View Plan Details</CardStorage>
            {openFeatureList ? <KeyboardArrowDown fontSize="small" /> : <KeyboardArrowUp fontSize="small" />}
          </IconButton>
        )}
        {viewList && (
          <List
            sx={{
              display: 'inline-block',
              marginBottom: isMobile ? 1 : 4,
              width: '100%',
              maxWidth: '300px',
              paddingTop: isMobile ? '0px' : '30px',
              minHeight: isMedium ? '0px' : '400px'
            }}
            dense
            disablePadding
          >
            <ListItem sx={{ py: 0 }} dense>
              <ListItemAvatar
                sx={{
                  minWidth: 34
                }}
              >
                <DevicesOutlined color="primary" />
              </ListItemAvatar>
              <ListItemText primary="Mobile auto backup" />
            </ListItem>
            <ListItem sx={{ py: 0 }} dense>
              <ListItemAvatar
                sx={{
                  minWidth: 34,
                  paddingTop: '5px'
                }}
              >
                <PhotoAlbumOutlined color="primary" />
              </ListItemAvatar>
              <ListItemText primary="Album creation" />
            </ListItem>
            <ListItem sx={{ py: 0 }} dense>
              <ListItemAvatar
                sx={{
                  minWidth: 34,
                  paddingTop: '5px'
                }}
              >
                <SortOutlined color="primary" />
              </ListItemAvatar>
              <ListItemText primary="Sorting tools" />
            </ListItem>
            <ListItem sx={{ py: 0 }} dense>
              <ListItemAvatar
                sx={{
                  minWidth: 34,
                  paddingTop: '5px'
                }}
              >
                <CameraEnhanceOutlined color="primary" />
              </ListItemAvatar>
              <ListItemText primary="Compression-free guarantee" />
            </ListItem>
            <ListItem sx={{ py: 0 }} dense>
              <ListItemAvatar
                sx={{
                  minWidth: 34,
                  paddingTop: '5px'
                }}
              >
                <SpaOutlined color="primary" />
              </ListItemAvatar>
              <ListItemText primary="Carbon-neutral memory management" />
            </ListItem>

            <ListItem sx={{ py: 0 }} dense>
              <ListItemAvatar
                sx={{
                  minWidth: 34,
                  paddingTop: '5px'
                }}
              >
                {plan !== 'storage' && <GroupsOutlined color="primary" />}
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#000000DE',
                      fontWeight: plan === 'sharing' ? 'bold' : 'inherit',
                      minHeight: '37px'
                    }}
                  >
                    {plan !== 'storage' && 'Group sharing'}
                    {plan !== 'storage' && (
                      <Tooltip
                        arrow
                        open={open}
                        PopperProps={{ sx: TooltipSx }}
                        title="Group Buckets lets you invite a select group to share photos & videos using one simple link, giving you total control and privacy."
                      >
                        <IconButton color="primary" style={{ zIndex: 100 }} onClick={handleClick}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                }
              />
            </ListItem>
            <ListItem sx={{ py: 0 }} dense>
              <ListItemAvatar
                sx={{
                  minWidth: 34,
                  paddingTop: '5px'
                }}
              >
                {plan !== 'storage' && <ScreenShareOutlined color="primary" />}
              </ListItemAvatar>
              <ListItemText
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight: plan === 'sharing' ? 'bold' : 'normal',
                    color: 'inherit'
                  }
                }}
                primary={`${plan !== 'storage' ? 'Personal & social sharing' : ''}`}
              />
            </ListItem>
            <ListItem sx={{ py: 0 }} dense>
              <ListItemAvatar
                sx={{
                  minWidth: 34,
                  paddingTop: '5px'
                }}
              >
                {plan !== 'storage' && <PhotoSizeSelectLargeOutlined color="primary" />}
              </ListItemAvatar>
              <ListItemText
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight: plan === 'sharing' ? 'bold' : 'normal',
                    color: 'inherit'
                  }
                }}
                primary={`${plan !== 'storage' ? 'Photo editing tools' : ''}`}
              />
            </ListItem>
            <ListItem sx={{ py: 0 }} dense>
              <ListItemAvatar
                sx={{
                  minWidth: 34,
                  paddingTop: '5px'
                }}
              >
                {plan !== 'storage' && <SlowMotionVideo color="primary" />}
              </ListItemAvatar>
              <ListItemText
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight: plan === 'sharing' ? 'bold' : 'normal',
                    color: 'inherit'
                  }
                }}
                primary={`${plan !== 'storage' ? 'Video playback' : ''}`}
              />
            </ListItem>
            <ListItem sx={{ py: 0 }} dense>
              <ListItemAvatar
                sx={{
                  minWidth: 34,
                  paddingTop: '5px',
                  paddingBottom: 1
                }}
              >
                {plan === 'expert' && <InsertLink color="primary" />}
              </ListItemAvatar>
              <ListItemText
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight: plan === 'expert' ? 'bold' : 'normal',
                    color: 'inherit',
                    paddingBottom: 1
                  }
                }}
                primary={`${plan === 'expert' ? 'Embed Links for hosting' : ''}`}
              />
            </ListItem>
          </List>
        )}
      </>
    )
  }

  return (
    <>
      {isMobile && (
        <IconButton
          disableRipple
          onClick={handleToggle}
          aria-label={!openFeatureList ? 'Expand' : 'Collapse'}
          sx={{
            color: 'primary.main',
            paddingTop: '30px',
            paddingBottom: '30px',
            height: '20px'
          }}
        >
          <CardStorage>View Plan Details</CardStorage>
          {openFeatureList ? <KeyboardArrowDown fontSize="small" /> : <KeyboardArrowUp fontSize="small" />}
        </IconButton>
      )}
      {viewList && (
        <List
          sx={{
            display: 'inline-block',
            marginBottom: isMobile ? 1 : 4,
            width: '100%',
            maxWidth: '300px',
            paddingTop: isMobile ? '0px' : '30px'
          }}
          dense
          disablePadding
        >
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34
              }}
            >
              <Check color="primary" />
            </ListItemAvatar>
            <ListItemText primary="Mobile auto backup" />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              <Check color="primary" />
            </ListItemAvatar>
            <ListItemText primary="Album creation" />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              <Check color="primary" />
            </ListItemAvatar>
            <ListItemText primary="Sorting tools" />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              <Check color="primary" />
            </ListItemAvatar>
            <ListItemText primary="Compression-free guarantee" />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              <Check color="primary" />
            </ListItemAvatar>
            <ListItemText primary="Carbon-neutral memory management" />
          </ListItem>

          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              {plan !== 'storage' ? <Check color="primary" /> : <Close color="error" />}
            </ListItemAvatar>
            <ListItemText
              primary={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: plan !== 'storage' ? '#000000DE' : theme.palette.customError[500],
                    fontWeight: plan === 'sharing' ? 'bold' : 'inherit',
                    minHeight: '37px'
                  }}
                >
                  Group sharing{' '}
                  {plan !== 'storage' && (
                    <Tooltip
                      arrow
                      open={open}
                      PopperProps={{ sx: TooltipSx }}
                      title="Group Buckets lets you invite a select group to share photos & videos using one simple link, giving you total control and privacy."
                    >
                      <IconButton color="primary" style={{ zIndex: 100 }} onClick={handleClick}>
                        <InfoOutlined fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              }
            />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              {plan !== 'storage' ? <Check color="primary" /> : <Close color="error" />}
            </ListItemAvatar>
            <ListItemText
              sx={{
                '& .MuiTypography-root': { fontWeight: plan === 'sharing' ? 'bold' : 'normal', color: plan !== 'storage' ? 'inherit' : 'error.main' }
              }}
              primary="Personal & social sharing"
            />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              {plan !== 'storage' ? <Check color="primary" /> : <Close color="error" />}
            </ListItemAvatar>
            <ListItemText
              sx={{
                '& .MuiTypography-root': { fontWeight: plan === 'sharing' ? 'bold' : 'normal', color: plan !== 'storage' ? 'inherit' : 'error.main' }
              }}
              primary="Photo editing tools"
            />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              {plan !== 'storage' ? <Check color="primary" /> : <Close color="error" />}
            </ListItemAvatar>
            <ListItemText
              sx={{
                '& .MuiTypography-root': {
                  fontWeight: plan === 'sharing' ? 'bold' : 'normal',
                  color: plan !== 'storage' ? 'inherit' : 'error.main'
                }
              }}
              primary="Video playback"
            />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px',
                paddingBottom: 1
              }}
            >
              {plan === 'expert' ? <Check color="primary" /> : <Close color="error" />}
            </ListItemAvatar>
            <ListItemText
              sx={{
                '& .MuiTypography-root': {
                  fontWeight: plan === 'expert' ? 'bold' : 'normal',
                  color: plan === 'expert' ? 'inherit' : 'error.main',
                  paddingBottom: 1
                }
              }}
              primary="Embed Links for hosting"
            />
          </ListItem>
        </List>
      )}
    </>
  )
}
