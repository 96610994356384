import { Alert, Box, Button, DialogContent, Typography, styled, useMediaQuery } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { getAuth } from 'firebase/auth'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import theme from '../../../../../config/theme'
import { UserProfileFragmentFragmentDoc } from '../../../../../graphql/generated'
import cache from '../../../../../providers/apollo/cache'
import useSubscriptionUpdate from '../../../../subscription/hooks/useSubscriptionUpate'
import useAlerts from '../../../hooks/useAlerts'

export const StyledButton = styled(Button)(() => ({
  background: 'linear-gradient(158.55deg, #202CD3 5.3%, #02E39F 102.34%)',
  borderRadius: '75px',
  border: 'none',
  color: '#fff',
  textTransform: 'none',
  fontSize: '0.875rem',
  fontWeight: 600,
  padding: '10px 30px',
  '&:hover': {
    background: 'linear-gradient(158.55deg, #02E39F 5.3%, #202CD3 102.34%)',
    border: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem'
  }
})) as typeof Button

interface UpgradeChangeModalProps {
  isOpen: boolean
  onClose: () => void
  title?: string
  content?: string
  boldContent?: string
  price?: string
  planName: string
  billingCycle: string
  contactUs: boolean
  currentTermEndsAt: string
}

const getTitleBillingCycle = (billingCycle: string) => {
  return billingCycle.toLowerCase() === 'annual' ? 'yearly' : billingCycle
}

const getSubTitleBillingCycle = (billingCycle: string) => {
  return billingCycle.toLowerCase() === 'annual' ? 'yearly' : 'monthly'
}

const getPlanName = (planName: string) => {
  switch (planName.toLowerCase()) {
    case 'storage':
      return 'My Bucket'
    case 'sharing':
      return 'Group Buckets'
    case 'premium':
    case 'expert':
      return 'Hosting'
    default:
      return capitalize(planName)
  }
}

function UpgradeChangeModal({
  isOpen,
  onClose,
  title,
  content,
  boldContent,
  price,
  planName,
  billingCycle,
  contactUs,
  currentTermEndsAt
}: Readonly<UpgradeChangeModalProps>) {
  const { createAlert } = useAlerts()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { updateSubscription } = useSubscriptionUpdate()
  const [errors, setErrors] = useState({ general: '' })

  const planNameTitle = getPlanName(planName)

  const handleChangePlan = () => {
    const userId = getAuth().currentUser?.uid
    if (!userId) {
      createAlert('There was an issue with your session. Please refresh the page and try again.', 'error')
    }
    const profile = cache.readFragment({
      id: `UserProfile:${userId}`,
      fragmentName: 'UserProfileFragment',
      fragment: UserProfileFragmentFragmentDoc
    })

    const subscriptionId = profile?.subscription?.id
    if (!subscriptionId) {
      createAlert('There was an issue fetching your subscription. Please try again later.', 'error')
      return
    }

    const cycle = billingCycle === 'yearly' ? 'annual' : billingCycle

    const planCode = planName === 'premium' ? `expert-${cycle}` : `${planName}-${cycle}`

    updateSubscription(subscriptionId, { planCode }, (apolloError) => {
      setErrors({ general: apolloError.message })
    })

    onClose()
  }

  const subBoxContent = !contactUs
    ? `The prorated balance will be billed today. Your first full payment of $${price} will be on ${currentTermEndsAt} and billing will recur on a monthly basis. The payment method of your current subscription will be used. You can cancel anytime.`
    : `Your next payment will be on ${currentTermEndsAt}. Any available credit will be applied to future amounts. Billing will recur on a monthly basis. The payment method of your current subscription will be used. You can cancel anytime.`

  return (
    <Dialog data-test="change-membership-modal" open={isOpen} onClose={onClose} maxWidth="sm">
      <Alert severity="error" sx={{ display: errors.general ? 'block' : 'none' }} />
      <DialogTitle sx={{ textAlign: 'center', paddingBottom: '8px' }}>
        <Typography color="primary" sx={{ fontWeight: '600', fontSize: '20px' }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: '24px', textAlign: 'center' }}>
        {/* Icon is a warning if we have contact support */}
        <Box sx={{ marginBottom: '16px' }}>
          {contactUs ? (
            <img
              src="https://content.photobucket.com/img/subscription/gradient_warning_icon.png"
              alt="Warning Icon"
              style={{ width: isMobile ? '50px' : '100px', height: isMobile ? '50px' : '100px' }}
            />
          ) : (
            <img
              src="https://content.photobucket.com/img/group-bucket/create-bucket.png"
              alt="Warning Icon"
              style={{ width: isMobile ? '50px' : '100px', height: isMobile ? '50px' : '100px' }}
            />
          )}
        </Box>
        <Typography sx={{ marginBottom: '16px', fontSize: '14px', lineHeight: '20px' }}>
          {content}
          <strong>{boldContent}</strong>. {contactUs && 'If you have any questions, please contact our '}
          {contactUs && (
            <Link
              to="https://support.photobucket.com/hc/en-us"
              target="_blank"
              style={{
                color: '#0056D2',
                fontWeight: '600',
                textDecoration: 'none'
              }}
            >
              Member Engagement Team.
            </Link>
          )}
        </Typography>
        <Box
          data-testid="plan-details-box"
          sx={{
            width: '100%',
            padding: '16px',
            textAlign: 'left',
            border: '1px solid #D0D5DD',
            borderRadius: '8px',
            marginBottom: '24px'
          }}
        >
          <Typography variant="subtitle1" data-testid="plan-change-title" sx={{ marginBottom: '4px', fontWeight: '600' }}>
            {planNameTitle} Plan, {capitalize(getTitleBillingCycle(billingCycle))}
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '4px', fontWeight: '500' }}>
            ${price}/{getSubTitleBillingCycle(billingCycle)}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: '400', lineHeight: '18px' }}>
            {subBoxContent}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: isMobile ? '' : '5px' }}>
          <Button
            sx={{ minWidth: '120px', fontWeight: '600', padding: '8px 16px', fontSize: isMobile ? '0.7rem' : '0.875rem' }}
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <StyledButton sx={{ minWidth: '40px', fontWeight: '600', padding: '8px 16px' }} color="error" onClick={handleChangePlan}>
            Agree & Change Plan
          </StyledButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

UpgradeChangeModal.defaultProps = {
  title: '',
  content: '',
  boldContent: '',
  price: ''
}

export default UpgradeChangeModal
